import * as moment from "moment";

export const getCurrentDate = () => moment().toDate();

export const getCurrentMonth = () => moment().get("month") + 1;

export const getCurrentYear = () => moment().get("year");

export const getCurrentDay = () => moment().get("date");

export const currentMonthRange = () => {
  const startOfMonth = moment()
    .startOf("month")
    .toDate();
  const endOfMonth = moment()
    .endOf("month")
    .toDate();
  return [startOfMonth, endOfMonth];
};

export const formatDate = (date: any, format = "YYYY-MM-DD") => {
  const d = moment(date).format(format);
  console.log(d);
  return d;
};

export const dateBetween = (checkDate, fromDate, toDate) => {
  const d1 = fromDate.split("/");
  const d2 = toDate.split("/");
  const c = checkDate.split("/");

  const from = new Date(d1[2], parseInt(d1[1]) - 1, d1[0]);
  const to = new Date(d2[2], parseInt(d2[1]) - 1, d2[0]);
  const check = new Date(c[2], parseInt(c[1]) - 1, c[0]);

  return check >= from && check <= to;
};
