import { Component, OnInit } from "@angular/core";
import { FormsModel } from "src/app/models/forms.model";
import { ORGANIZTION_OPS_MODEL } from "../../models/organization-ops-form.model";

@Component({
  selector: "app-organization-ops",
  templateUrl: "./organization-ops.component.html",
  styleUrls: ["./organization-ops.component.scss"]
})
export class OrganizationOpsComponent implements OnInit {
  organizationModel: FormsModel;
  constructor() {
    this.organizationModel = ORGANIZTION_OPS_MODEL;
  }

  ngOnInit(): void {}
}
