import { Component, OnInit, Input, OnChanges } from "@angular/core";
import { colors } from "src/app/models/colors";

@Component({
  selector: "app-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"]
})
export class ButtonComponent implements OnChanges {
  @Input() type: string;
  @Input() color: colors;
  @Input() small: boolean;
  @Input() block: boolean;
  classList: string[];
  constructor() {
    this.classList = ["btn"];
  }

  ngOnChanges(changes): void {
    if (changes) {
      if (changes.color) {
        this.classList.push(`btn-${changes.color.currentValue}`);
      }
      if (changes.small) {
        this.classList.push(`btn-sm`);
      }
      if (changes.block) {
        this.classList.push(`btn-block`);
      }
    }
  }
}
