import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable()
export class LoaderService {
  private loadingStatus: boolean = false;
  private loadingSubject = new Subject<boolean>();
  loadingObservable = this.loadingSubject.asObservable();
  constructor() {}

  showLoader() {
    this.loadingStatus = true;
    this.loadingSubject.next(this.loadingStatus);
  }
  hideLoader() {
    this.loadingStatus = false;
    this.loadingSubject.next(this.loadingStatus);
  }
}
