import {
  Directive,
  ElementRef,
  Input,
  SimpleChanges,
  OnChanges
} from "@angular/core";

@Directive({
  selector: "div[appRow]"
})
export class RowDirective implements OnChanges {
  @Input() class: string;
  className: string[];
  element: HTMLDivElement;
  constructor(private eRef: ElementRef) {
    this.element = this.eRef.nativeElement;
    this.className = ["row"];
    this.addClasses();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      if (changes.class) {
        this.className.push(changes.class.currentValue);
      }
      this.addClasses();
    }
  }
  arrayToClass() {
    return this.className.join(" ");
  }
  addClasses() {
    this.element.className = this.arrayToClass();
  }
}
