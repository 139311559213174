import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import { Title } from "@angular/platform-browser";

@Injectable()
export class TitleService {
  private _title: string = "";
  private titleSubject = new BehaviorSubject<string>(this._title);
  titleObservable = this.titleSubject.asObservable();
  constructor(private titleService: Title) {}

  setTitle(title: string) {
    this._title = title;
    this.titleSubject.next(this._title);
    this.titleService.setTitle(this._title);
  }
}
