import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { FileService } from 'src/app/files/services/file.service';
import { UserService } from '../../services/user.service';
import { UserModel } from 'src/app/dashboard/models/user.model';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {
  @Input() data = [];
 
  @Output() edit = new EventEmitter();
  userList:any[];
  constructor(private loader:LoaderService,private userService:UserService) { }
  
  
  editUser(data) {
    console.log("I am in edit");
    console.log(data);

    this.edit.emit(data);
  }

  ngOnInit(): void {
    
  }

  

}
