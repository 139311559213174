import { Injectable } from "@angular/core";
import { FormsyModule } from "../formsy.module";
import { FormsModel } from "src/app/models/forms.model";
import { FormGroup, FormControl } from "@angular/forms";

@Injectable()
export class FormBuilderService {
  constructor() {}

  createFormGroup(model: FormsModel): FormGroup {
    let form: FormGroup = new FormGroup({});
    for (let ctrl of model.controls) {
      const c = this.createControl(
        ctrl.validators,
        ctrl.updateOn,
        ctrl.defaultValue
      );
      form.addControl(ctrl.name, c);
    }
    return form;
  }

  private createControl(
    validators: any[],
    updateOn: "change" | "blur" | "submit" = "blur",
    defaultValue = null
  ) {
    return new FormControl(defaultValue, { validators, updateOn });
  }
}
