import { NgModule } from "@angular/core";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { PanelMenuModule } from "primeng/panelmenu";
import { MenuComponent } from "./components/menu/menu.component";
import { SidebarModule } from "primeng/sidebar";
import { HeaderComponent } from "./components/header/header.component";
import { LogoComponent } from "./components/logo/logo.component";
import { BlueHeaderComponent } from "./components/blue-header/blue-header.component";
import { SharedModule } from "../shared/shared.module";

@NgModule({
  declarations: [
    SidebarComponent,
    MenuComponent,
    HeaderComponent,
    LogoComponent,
    BlueHeaderComponent
  ],
  imports: [SharedModule, SidebarModule, PanelMenuModule],
  exports: [
    SidebarComponent,
    HeaderComponent,
    BlueHeaderComponent,
    LogoComponent
  ]
})
export class UiModule {}
