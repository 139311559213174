import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  TemplateRef,
  ElementRef,
  AfterViewInit,
} from "@angular/core";

@Component({
  selector: 'app-excel-raw-table',
  templateUrl: './excel-raw-table.component.html',
  styleUrls: ['./excel-raw-table.component.scss']
})
export class ExcelRawTableComponent  implements OnInit {
  @Input() data: any;
  @Input() width: number = 100;
  dataTable:any;
  @ViewChild('dataTable') excelContainer: ElementRef;
  tableData:any;

  constructor() { }

  ngOnInit(): void {
    console.log("data value "+this.data);
   // var jsonObj = JSON.parse(this.data);
    this.tableData=this.data;
   // this.tableData = this.data.split(",");
    //console.log("table data value "+this.tableData); 
    // this.tableData = {
    //   data: this.tableData,
    // };
    // this.dataTable = this.excelContainer.nativeElement;
    // this.dataTable.DataTable(this.tableData);

  }

}
