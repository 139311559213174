import { FormsModel } from "src/app/models/forms.model";
import { Validators } from "@angular/forms";

export const ORGANIZTION_OPS_MODEL: FormsModel = {
  controls: [
    {
      label: "Orgnization Name",
      type: "text",
      name: "orgName",
      validators: [Validators.required]
    },
    {
      label: "Short Code",
      type: "text",
      name: "shortCode",
      validators: [Validators.required]
    },
    {
      label: "Status",
      type: "text",
      name: "status",
      validators: [Validators.required]
    },
    {
      label: "ABA No",
      type: "text",
      name: "abaNo",
      validators: [Validators.required]
    },
    {
      label: "Cert",
      type: "text",
      name: "cert",
      validators: [Validators.required]
    },
    {
      label: "Notes",
      type: "multiline",
      name: "shortCode",
      validators: [Validators.required]
    },
    {
      label: "Organization Type",
      type: "text",
      name: "orgType",
      validators: [Validators.required]
    },
    {
      label: "Description",
      type: "multiline",
      name: "description",
      validators: [Validators.required]
    }
  ]
};
