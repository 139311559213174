import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef
} from "@angular/core";
import * as jexcel from "jexcel";

@Component({
  selector: "app-excel-view",
  templateUrl: "./excel-view.component.html",
  styleUrls: ["./excel-view.component.scss"]
})
export class ExcelViewComponent implements OnInit, AfterViewInit {
  @ViewChild("excelBlock") excelContainer: ElementRef;
  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.loadExcel();
  }

  loadExcel() {
    const data = [
      ["Jazz", "Honda", "2019-02-12", "", true, "$ 2.000,00", "#777700"],
      ["Civic", "Honda", "2018-07-11", "", true, "$ 4.000,01", "#007777"]
    ];

    jexcel(this.excelContainer.nativeElement, {
      data,
      columns: [
        {
          type: "text",
          title: "Car",
          width: 90
        },
        {
          type: "dropdown",
          title: "Make",
          width: 120,
          source: [
            "Alfa Romeo",
            "Audi",
            "Bmw",
            "Chevrolet",
            "Chrystler",
            "Dodge",
            "Ferrari",
            "Fiat",
            "Ford",
            "Honda",
            "Hyundai",
            "Jaguar",
            "Jeep",
            "Kia",
            "Mazda",
            "Mercedez-Benz",
            "Mitsubish",
            "Nissan",
            "Peugeot",
            "Porsche",
            "Subaru",
            "Suzuki",
            "Toyota",
            "Volkswagen"
          ]
        },
        {
          type: "calendar",
          title: "Available",
          width: 120
        },
        {
          type: "image",
          title: "Photo",
          width: 120
        },
        {
          type: "checkbox",
          title: "Stock",
          width: 80
        },
        {
          type: "numeric",
          title: "Price",
          mask: "$ #.##,00",
          width: 80,
          decimal: ","
        },
        {
          type: "color",
          width: 80,
          render: "square"
        }
      ]
    });
  }
}
