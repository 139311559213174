import { NgModule } from "@angular/core";
import { ShowErrorsComponent } from "./components/show-errors/show-errors.component";
import { CommonModule } from "@angular/common";

@NgModule({
  declarations: [ShowErrorsComponent],
  imports: [CommonModule],
  exports: [ShowErrorsComponent]
})
export class ValidatorsModule {}
