import { NgModule, ErrorHandler, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ErrorHandlerService } from "./services/error-handler.service";
import { ErrorPageComponent } from "./components/error-page/error-page.component";

@NgModule({
  declarations: [ErrorPageComponent],
  imports: [CommonModule],
  exports: [ErrorPageComponent]
})
export class ErrorModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ErrorModule,
      providers: [{ provide: ErrorHandler, useClass: ErrorHandlerService }]
    };
  }
}
