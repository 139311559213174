import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SpinnerComponent } from "./components/spinner/spinner.component";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { LoaderService } from "./services/loader.service";

@NgModule({
  declarations: [SpinnerComponent],
  imports: [CommonModule, ProgressSpinnerModule],
  exports: [SpinnerComponent]
})
export class LoaderModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: LoaderModule,
      providers: [LoaderService]
    };
  }
}
