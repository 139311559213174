import { Injectable, ErrorHandler } from "@angular/core";
import { ToastService } from "src/app/core/services/toast.service";

@Injectable()
export class ErrorHandlerService implements ErrorHandler {
  constructor(private toast: ToastService) {}
  handleError(error) {
    // do something with the exception
    console.log(error);
    if (
      !String(error).includes("ExpressionChangedAfterItHasBeenCheckedError")
    ) {
     // this.toast.showError("something went wrong", "GLOBAL ERROR");
    }
  }
}
