import { Component, OnInit, Input } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "app-radio",
  templateUrl: "./radio.component.html",
  styleUrls: ["./radio.component.css"]
})
export class RadioComponent implements OnInit {
  @Input() label: string;
  @Input() name: string;
  @Input() class: string;
  @Input() value: string;
  @Input() ctrl: FormControl;
  constructor() {}

  ngOnInit(): void {}
}
