/**
 * @param  {any[]} data
 * @param  {string} keyCol
 * @param  {string} valCol
 * @returns string
 */
export function reMapForAutoComplete(
  data: any[],
  keyCol: string,
  valCol: string
): { key: string | number; value: string }[] {
  return data.map(d => ({ key: d[keyCol], value: d[valCol] }));
}
