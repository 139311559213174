import { Component, OnInit, Input } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ERROR_MESSAGE } from "../../shared/error-messages";

@Component({
  selector: "app-show-errors",
  templateUrl: "./show-errors.component.html",
  styleUrls: ["./show-errors.component.scss"]
})
export class ShowErrorsComponent implements OnInit {
  @Input() ctrl: FormControl;
  @Input() hideMessages: boolean;
  ERROR_MESSAGES: any;
  constructor() {
    this.ERROR_MESSAGES = ERROR_MESSAGE;
  }

  ngOnInit() {}

  shouldShowErrors(): boolean {
    return (
      !this.hideMessages && this.ctrl && this.ctrl.errors && this.ctrl.touched
    );
  }

  listOfErrors(): string[] {
    return Object.keys(this.ctrl.errors).map(err =>
      this.ERROR_MESSAGES[err](this.ctrl.getError(err))
    );
  }
}
