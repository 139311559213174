import {
  Directive,
  Input,
  OnChanges,
  SimpleChanges,
  ElementRef
} from "@angular/core";

@Directive({
  selector: "div[appColumn]"
})
export class ColumnDirective implements OnChanges {
  @Input() class: string;
  @Input() size: number;
  className: string[];
  element: HTMLDivElement;
  constructor(private eRef: ElementRef) {
    this.element = this.eRef.nativeElement;
    this.className = ["col"];
    this.addClasses();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.className = [];
    if (changes) {
      if (changes.size) {
        this.className.push(`col-md-${changes.size.currentValue}`);
      }
      if (changes.class) {
        this.className.push(changes.class.currentValue);
      }
      this.addClasses();
    }
  }
  arrayToClass() {
    return this.className.join(" ");
  }
  addClasses() {
    this.element.className = this.arrayToClass();
  }
}
