import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ExcelViewComponent } from "./components/excel-view/excel-view.component";

@NgModule({
  declarations: [ExcelViewComponent],
  imports: [CommonModule],
  exports: [ExcelViewComponent]
})
export class ExcelModule {}
