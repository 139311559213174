import { NgModule } from "@angular/core";
import { RulesTableComponent } from "./components/rules-table/rules-table.component";
import { SharedModule } from "../shared/shared.module";
import { RulesService } from "./services/rules.service";
import { ExcelTemplateTableComponent } from "./components/excel-template-table/excel-template-table.component";
import { RulesSelectorComponent } from "./components/rules-selector/rules-selector.component";
import { ExcelRawTableComponent } from './components/excel-raw-table/excel-raw-table.component';

@NgModule({
  declarations: [
    RulesTableComponent,
    ExcelTemplateTableComponent,
    RulesSelectorComponent,
    ExcelRawTableComponent
  ],
  imports: [SharedModule],
  exports: [
    RulesTableComponent,
    ExcelTemplateTableComponent,
    RulesSelectorComponent,
    ExcelRawTableComponent
  ],
  providers: [RulesService]
})
export class IngestionRulesModule {}
