import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  TemplateRef,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import * as jexcel from "jexcel";
import { LoaderService } from "src/app/loader/services/loader.service";

@Component({
  selector: "app-excel-template-table",
  templateUrl: "./excel-template-table.component.html",
  styleUrls: ["./excel-template-table.component.scss"],
})
export class ExcelTemplateTableComponent
  implements OnInit, OnChanges, AfterViewInit {
  @Input() data: any;
  @Input() width: number = 100;
  @ViewChild("excelBlock") excelContainer: ElementRef;
  hasLoaded: boolean = false;
  loaded: boolean = false;
  constructor(private loader: LoaderService) {}

  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.data && this.hasLoaded) {
      this.loaded = true;
      this.excelContainer.nativeElement.innerHTML = "";
      this.loadExcel(changes.data.currentValue);
    }
  }
  modifyHeader(data: any[]) {
    const header = data.shift();
    return header.map((h) => ({ title: h }));
  }
  ngAfterViewInit() {
    this.hasLoaded = true;
    this.loadExcel(this.data);
  }
  loadExcel(data) {
    if (data) {
      this.loaded = true;
      // const columns = this.modifyHeader(data);
      const width = Number(this.width) / 100;
      jexcel(this.excelContainer.nativeElement, {
        data,
        //  columns,
        columnDrag: true,
        tableOverflow: true,
        tableWidth: `${window.innerWidth * width}px`,
        loadingSpin: true,
        lazyLoading: true,
        defaultColWidth: 200,
        onload: () => (this.loaded = false),
      }).setWidth(1, 400);
    }
  }
}
